/* Reset default margin and padding */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: Arial, sans-serif;
  background-color: #f4f4f4;
  height: 100vh;
}

input{
  outline: none;
}
.clear-icon{
  font-weight: 200;
  margin-left: -35px;
}


.left-section {
  padding: 50px;
  overflow: hidden;
}

.right-section {
  padding: 50px;
   display: flex;
  flex-wrap: wrap; /* Allow items to wrap */
  align-content: flex-start; /* Align items at the start */
   overflow-y: auto;
 
}

.search-section {
  flex-basis: 45%; /* Each search section takes up 45% of the right section */
  margin-right: 5%;
  margin-bottom: 20px; /* Add some spacing between search sections */
  flex-wrap: wrap;
}

.search-results {
  margin-top: 0; /* Remove margin-top */
}

.clear {
  clear: both;
}

.container h2 {
  font-size: 2em;
  margin-bottom: 20px;
  color: #616161;
}
.container h4{
  font-size: 20px;
  margin-bottom: 20px;
  color: gray;
}

input[type="text"],
textarea {
  width: calc(100% - 20px);
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1em;
}

.search-bar input[type="text"] {
  padding-right: 50px; /* Adjust the padding as needed */
}

textarea {
  height: 100px;
}
.search-results {
  margin-top: 20px;
}

.search-results a {
  display: block;
  margin-bottom: 20px;
  text-decoration: none;
  color: #333;
  transition: all 0.3s ease;
}

.search-results a:hover {
  color: #007bff;
}

.search-results img {
  display: block;
  width: 100%;
  height: 130px;
  border-radius: 8px;
}

.search-results h3 {
  margin-top: 20px;
  font-family: "poppins";
  font-size: 15px;
}
.savebtn{
  border-radius: 10px;
  font-weight: 500;
  margin-top: 15px;
  background-color: rgb(56, 168, 0);
}
.search-results p {
  margin-top: 5px;
  font-size: 12px;
  color: #666;
}
#googleResults h3{
  margin-top: 60px;
}
#googleResults p{
  margin-top: 20px;
}
.right-section .errormsg {
  font-size: 10px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  color: white;
  padding-top: 10px;
  height: 40px;
  width: 50%;
  border-radius: 10px;
  font-family: "poppins";
  display: block;
  background-color: rgb(255, 119, 0);
  animation: fadeInOut 4s ease-in-out forwards;
}
.right-section .container .row .spinner-container{
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.result-item {
  display: flex;
  align-items: center;
}

.result-checkbox {
  margin-right: 10px;
  margin-bottom: 90px;
}

.result-content {
  flex-grow: 1;
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}


button {
  width: 97%;
  padding: 10px;
  margin: 10px 0;
  border: none;
  border-radius: 20px;
  background-color: #007bff;
  color: #fff;
  font-size: 1em;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #0056b3;
}
.butpla{
  margin-top: 20px;
  border-radius: 10px;
  font-weight: 600;
  background-color: orange;
}